import React, { Fragment } from 'react'
import posed, { PoseGroup } from 'react-pose'
import {
    isMobile
  } from "react-device-detect";
import Chevron from '../../images/chevron-up.svg'
import './study-design.scss';

const Content = posed.div({
    closed: { 
        height: 0 
    },
    open: { 
        height: 'auto'
     }
  });

const Arrow = posed.div({
    closed: {rotateX: 180},
    open: {rotateX: 0}
});


class StudyDesign extends React.Component {

    state = { open: false };

    render (){
        const { open } = this.state;
        const { children } = this.props;
        const word = open ? 'HIDE':'VIEW';

        return (  
            <div className='study-container'>
                <h2
                className="title"
                onClick={() => this.setState({ open: (open) ? false : true })}
                >
                {`${word} STUDY DESIGN`}
                <Arrow className='study-arrow' pose={open ? 'open': 'closed'}><img src={Chevron}/></Arrow>
                </h2>
                <Content className="study-content" pose={open ? 'open' : 'closed'}>
                    <div className="study-content-wrapper">{children}</div>
                </Content>
            </div>
        )
    }
}

export default StudyDesign
